import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import {
  Bars3Icon as MenuIcon,
  XMarkIcon as XIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "../contexts/TranslationContext";

const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isAboutOrHome =
    location.pathname === "/about" || location.pathname === "/";

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // Move navigation array inside the component to access t function
  const navigation = [
    { name: t("navigation.aboutUs"), href: "/about" },
    { name: t("navigation.leadershipTeam"), href: "/leadership-team" },
    { name: t("navigation.products"), href: "/products" },
    { name: t("navigation.consulting"), href: "/consulting" },
    { name: t("navigation.blogs"), href: "/blogs" },
    { name: t("navigation.contactUs"), href: "/contact", emphasize: true },
  ];

  return (
    <Disclosure as="nav" className="shadow mb-4">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="flex items-center">
                    <img
                      className="h-12 w-auto"
                      src={
                        isAboutOrHome
                          ? "/images/white_logo.png"
                          : "/images/blue_logo.png"
                      }
                      alt="CyfrOne Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-8 sm:flex-grow sm:justify-end">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`relative inline-flex items-center text-lg font-bold px-3 py-2 ${
                      item.emphasize
                        ? isAboutOrHome
                          ? "px-6 py-2 bg-emphasize text-primary rounded-full hover:bg-emphasize/90 transition-colors border-4 border-white"
                          : "px-6 py-2 bg-emphasize text-primary rounded-full hover:bg-emphasize/90 transition-colors border-4 border-primary"
                        : isActive(item.href)
                        ? isAboutOrHome
                          ? "text-white border-b-4 border-white"
                          : "text-primary border-b-4 border-primary"
                        : isAboutOrHome
                        ? "text-white hover:text-gray-200"
                        : "text-primary hover:text-primary"
                    } `}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-text hover:text-primary hover:bg-background focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`${
                    item.emphasize
                      ? isAboutOrHome
                        ? "bg-emphasize text-primary hover:bg-emphasize/90"
                        : "bg-emphasize text-primary hover:bg-emphasize/90"
                      : isActive(item.href)
                      ? "bg-primary border-primary text-white"
                      : "border-transparent text-text hover:bg-primary hover:border-primary hover:text-white"
                  } block px-4 py-2 text-base font-medium rounded-lg mx-2`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
