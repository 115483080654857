import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import CarouselHeader from "./CarouselHeader";
import WhatsAppButton from "./WhatsAppButton";
import LanguageToggle from "./LanguageToggle";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isAboutOrHome =
    location.pathname === "/about" || location.pathname === "/";

  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setHeaderHeight = () => {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        document.documentElement.style.setProperty(
          "--header-height",
          `${height}px`
        );
      }
    };

    setHeaderHeight();
    window.addEventListener("resize", setHeaderHeight);

    return () => {
      window.removeEventListener("resize", setHeaderHeight);
    };
  }, [isAboutOrHome]);

  return (
    <div className="flex flex-col min-h-screen">
      {isAboutOrHome && (
        <div className="absolute top-0 left-0 right-0 z-0">
          <div
            className="w-full bg-cover bg-center"
            style={{
              backgroundImage: "url('/images/header_bg.jpg')",
              height: "var(--header-height)",
            }}
          ></div>
          <div
            className="absolute top-0 left-0 right-0 bg-contain bg-no-repeat bg-top"
            style={{
              backgroundImage: "url('/images/header_bg_lines.png')",
              height: "var(--header-height)",
              mixBlendMode: "overlay",
            }}
          ></div>
        </div>
      )}
      <div
        className={`relative z-10 ${isAboutOrHome ? "text-white" : ""}`}
        ref={headerRef}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="flex justify-end">
            <LanguageToggle />
          </div>
        </div>
        <Navigation />
        {isAboutOrHome && <CarouselHeader />}
      </div>
      <main className="flex-grow relative z-10">{children}</main>
      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default Layout;
