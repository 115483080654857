import React from "react";
import { motion } from "framer-motion";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "../contexts/TranslationContext";

interface TeamMember {
  id: number;
  name: string;
  expertise: string;
  designation: string;
  image: string;
  profileLink: string;
  linkedIn: string;
  bio?: string;
}

const ConsultingTeamSection: React.FC = () => {
  const { t, language } = useTranslation();

  const teamMembers: TeamMember[] = [
    {
      id: 1,
      name: t("consulting.team.members.0.name"),
      expertise: t("consulting.team.members.0.expertise"),
      designation: t("consulting.team.members.0.designation"),
      image: "/images/riaz_consult_small.jpg",
      profileLink: "/leadership-team#founder-profiles",
      linkedIn: "https://www.linkedin.com/in/riazuddin-mohammed-a810108/",
      bio: t("consulting.team.members.0.bio"),
    },
    {
      id: 2,
      name: t("consulting.team.members.1.name"),
      expertise: t("consulting.team.members.1.expertise"),
      designation: t("consulting.team.members.1.designation"),
      image: "/images/tabish_consult_small.jpg",
      profileLink: "/leadership-team#founder-profiles",
      linkedIn: "https://www.linkedin.com/in/tabishimam/",
      bio: t("consulting.team.members.1.bio"),
    },
  ];

  return (
    <motion.section
      className="py-16 my-8 bg-gradient-to-br from-gray-100 via-white to-gray-50 rounded-2xl shadow-lg"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          className="text-3xl md:text-4xl font-bold mb-16 text-center text-primary"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {t("consulting.team.title")}
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-12 max-w-4xl mx-auto">
          {teamMembers.map((member) => (
            <motion.div
              key={member.id}
              className="bg-white rounded-xl shadow-lg p-6 transform hover:shadow-xl transition-all duration-300"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              whileHover={{ y: -5 }}
            >
              <div className="flex flex-col items-center text-center">
                <motion.div
                  className="relative mb-6"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-48 h-48 rounded-full object-cover shadow-lg"
                  />
                  <div
                    className={`absolute -bottom-2 ${
                      language === "ar" ? "-left-2" : "-right-2"
                    }`}
                  >
                    <motion.a
                      href={member.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-white p-2 rounded-full shadow-md hover:shadow-lg transition-shadow"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaLinkedin size={24} className="text-blue-600" />
                    </motion.a>
                  </div>
                </motion.div>

                <div className="space-y-2">
                  <Link
                    to={member.profileLink}
                    className="text-2xl font-bold text-primary hover:text-primary/80 transition-colors"
                  >
                    {member.name}
                  </Link>
                  <p className="text-lg font-semibold text-gray-700">
                    {member.designation}
                  </p>
                  <p className="text-primary/80 font-medium">
                    {member.expertise}
                  </p>
                  {member.bio && (
                    <p className="text-gray-600 mt-2">{member.bio}</p>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <Link
            to="/contact"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-lg font-medium rounded-full text-white bg-primary hover:bg-primary/90 transition-all duration-300 transform hover:scale-105"
          >
            {t("consulting.team.consultNow")}
            <svg
              className={`${
                language === "ar" ? "mr-2 -ml-1 rotate-180" : "ml-2 -mr-1"
              } w-5 h-5`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </Link>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ConsultingTeamSection;
