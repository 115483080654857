import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";

interface Service {
  title: string;
  description: string;
  icon: string;
  bgColor: string;
}

const ConsultingServicesList: React.FC = () => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const services = t("consulting.services.items") as unknown as Service[];

  return (
    <section id="cslist" className="py-16 scroll-mt-24">
      <motion.h2
        className="text-3xl md:text-4xl font-bold mb-16 text-center text-primary"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        {t("consulting.services.title")}
      </motion.h2>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {services.map((service: any, index: number) => (
          <motion.div
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
            className={`${service.bgColor} p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow`}
          >
            <div className="space-y-4">
              <div className="text-5xl">{service.icon}</div>
              <h3 className="text-xl font-bold text-primary">
                {service.title}
              </h3>
              <p className="text-gray-700 leading-relaxed text-justify">
                {service.description}
              </p>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export default ConsultingServicesList;
