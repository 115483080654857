import React, { useState, ChangeEvent, FormEvent } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { useTranslation } from "../contexts/TranslationContext";

interface ContactFormData {
  name: string;
  email: string;
  phone?: string;
  subject: string;
  message: string;
  purpose:
    | "Consulting"
    | "Products"
    | "Products - OASIS"
    | "Executive Coaching"
    | "Others";
}

const initialFormData: ContactFormData = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  purpose: "Consulting",
};

const ContactForm: React.FC = () => {
  const { t, language } = useTranslation();
  const [formData, setFormData] = useState<ContactFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const formFields = [
    {
      type: "text",
      name: "name",
      placeholder: t("contact.form.fields.name"),
      required: true,
      autoComplete: "name",
    },
    {
      type: "email",
      name: "email",
      placeholder: t("contact.form.fields.email"),
      required: true,
      autoComplete: "email",
    },
    {
      type: "tel",
      name: "phone",
      placeholder: t("contact.form.fields.phone"),
      required: false,
      autoComplete: "tel",
    },
    {
      type: "text",
      name: "subject",
      placeholder: t("contact.form.fields.subject"),
      required: true,
    },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const templateParams: Record<string, unknown> = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message,
        purpose: formData.purpose,
      };

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_INBOUND_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      );

      setIsSuccess(true);
      setFormData(initialFormData);

      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } catch (error) {
      setError(t("contact.form.messages.error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.form
      onSubmit={handleSubmit}
      className="space-y-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      {formFields.map((field) => (
        <motion.div
          key={field.name}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <input
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name as keyof ContactFormData] as string}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary outline-none transition-colors"
            required={field.required}
            disabled={isLoading}
            autoComplete={field.autoComplete}
          />
        </motion.div>
      ))}

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        <label
          htmlFor="purpose"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {t("contact.form.fields.purpose.label")}
        </label>
        <select
          id="purpose"
          name="purpose"
          value={formData.purpose}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary outline-none transition-colors"
          required
          disabled={isLoading}
        >
          <option value="Consulting">
            {t("contact.form.fields.purpose.options.consulting")}
          </option>
          <option value="Products">
            {t("contact.form.fields.purpose.options.products")}
          </option>
          <option value="Products - OASIS">
            {t("contact.form.fields.purpose.options.oasis")}
          </option>
          <option value="Executive Coaching">
            {t("contact.form.fields.purpose.options.coaching")}
          </option>
          <option value="Others">
            {t("contact.form.fields.purpose.options.others")}
          </option>
        </select>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <textarea
          name="message"
          placeholder={t("contact.form.fields.message")}
          value={formData.message}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary/50 focus:border-primary outline-none transition-colors resize-y min-h-[150px]"
          required
          disabled={isLoading}
        />
      </motion.div>

      {error && (
        <motion.div
          className="text-red-500 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {error}
        </motion.div>
      )}

      {isSuccess && (
        <motion.div
          className="text-green-500 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {t("contact.form.messages.success")}
        </motion.div>
      )}

      <motion.button
        type="submit"
        className="w-full bg-primary text-white font-semibold p-3 rounded-lg hover:bg-primary/90 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors"
        disabled={isLoading}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg
              className={`animate-spin h-5 w-5 text-white ${
                language === "ar" ? "ml-3 -mr-1" : "-ml-1 mr-3"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {t("contact.form.button.sending")}
          </div>
        ) : (
          t("contact.form.button.send")
        )}
      </motion.button>
    </motion.form>
  );
};

export default ContactForm;
