import React from "react";
import { motion } from "framer-motion";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "../contexts/TranslationContext";

const LeadershipTeamFounderProfiles: React.FC = () => {
  const { t, language } = useTranslation();

  const founders = [
    {
      name: t("leadershipTeam.founders.founderProfiles.0.name"),
      title: t("leadershipTeam.founders.founderProfiles.0.title"),
      image: "/images/riaz_consult.webp",
      bio: t("leadershipTeam.founders.founderProfiles.0.bio"),
      linkedIn: "https://www.linkedin.com/in/riazuddin-mohammed-a810108/",
      expertise: t(
        "leadershipTeam.founders.founderProfiles.0.expertise"
      ) as unknown as string[],
    },
    {
      name: t("leadershipTeam.founders.founderProfiles.1.name"),
      title: t("leadershipTeam.founders.founderProfiles.1.title"),
      image: "/images/tabish_consult.webp",
      bio: t("leadershipTeam.founders.founderProfiles.1.bio"),
      linkedIn: "https://www.linkedin.com/in/tabishimam/",
      expertise: t(
        "leadershipTeam.founders.founderProfiles.1.expertise"
      ) as unknown as string[],
    },
  ];

  return (
    <section className="py-12">
      <motion.h2
        className="text-3xl md:text-4xl font-bold text-primary mb-16 text-center"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        {t("leadershipTeam.founders.sectionTitle")}
      </motion.h2>

      <div className="space-y-24">
        {founders.map((founder, index) => (
          <motion.div
            key={founder.name}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <div
              className={`flex flex-col ${
                language === "ar"
                  ? index % 2 === 0
                    ? "md:flex-row-reverse"
                    : "md:flex-row"
                  : index % 2 === 0
                  ? "md:flex-row"
                  : "md:flex-row-reverse"
              } gap-8 items-center`}
            >
              <div className="w-full md:w-2/5">
                <motion.div
                  className="relative group"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src={founder.image}
                    alt={founder.name}
                    className="w-full rounded-2xl shadow-xl"
                  />
                  <div className="absolute inset-0 bg-primary/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
                </motion.div>
              </div>

              <div className="w-full md:w-3/5">
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <div>
                      <h3 className="text-2xl md:text-3xl font-bold text-primary">
                        {founder.name}
                      </h3>
                      <p className="text-xl text-gray-600">{founder.title}</p>
                    </div>
                    <motion.a
                      href={founder.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 transition-colors"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaLinkedin size={32} />
                    </motion.a>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {founder.expertise.map((skill) => (
                      <span
                        key={skill}
                        className="px-4 py-1 bg-primary/10 text-primary rounded-full text-sm font-medium"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>

                  <div className="prose max-w-none">
                    {Array.isArray(founder.bio) &&
                      founder.bio.map((paragraph, i) => (
                        <motion.p
                          key={i}
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          transition={{ delay: 0.2 * i }}
                          className="mb-4 text-gray-700 text-justify leading-relaxed"
                        >
                          {paragraph.trim()}
                        </motion.p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default LeadershipTeamFounderProfiles;
