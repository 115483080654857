import React from "react";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";
import TestimonialCarousel from "../components/TestimonialCarousel";
import SEO from "../components/SEO";

interface Feature {
  title: string;
  description: string;
  icon: string;
}

const Products: React.FC = () => {
  const { t } = useTranslation();

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  /*const features: {
    title: string;
    description: string;
    icon: React.ReactNode;
  }[] = JSON.parse(t("products.features.items"));*/

  const features = t("products.features.items") as unknown as Feature[];

  return (
    <>
      <SEO
        title={t("products.seo.title")}
        description={t("products.seo.description")}
        keywords={[
          "OASIS",
          "Restaurant Management",
          "SaaS Platform",
          "Restaurant Solutions",
          "Customer Experience",
          "Business Optimization",
        ]}
        schema={{
          "@type": "Product",
          name: t("products.seo.schema.name"),
          description: t("products.seo.schema.description"),
          brand: {
            "@type": "Brand",
            name: "CyfrOne",
          },
          category: "Restaurant Management Software",
          applicationCategory: "BusinessApplication",
        }}
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Hero Section */}
          <motion.section className="text-center mb-16" {...fadeInUp}>
            <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
              {t("products.hero.title")}
            </h1>
            <div className="max-w-4xl mx-auto">
              <p className="text-xl text-gray-600 leading-relaxed mb-8 text-justify">
                {t("products.hero.intro1")}
              </p>
              <p className="text-xl text-gray-600 leading-relaxed text-justify">
                {t("products.hero.intro2")}
              </p>
            </div>
          </motion.section>

          {/* Video Section */}
          <motion.section
            className="mb-24"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="relative pt-[56.25%] rounded-2xl overflow-hidden shadow-2xl bg-gray-900">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=UloYNIA2iLc"
                width="100%"
                height="100%"
                controls={true}
                className="absolute top-0 left-0"
              />
            </div>
          </motion.section>

          {/* Features Section */}
          <motion.section
            className="mb-24"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <h2 className="text-3xl font-bold text-primary mb-12 text-center">
              {t("products.features.title")}
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 + index * 0.1 }}
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-semibold text-primary mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* Testimonials Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-primary mb-12 text-center">
              {t("products.testimonials.title")}
            </h2>
            <div className="bg-white rounded-2xl shadow-lg p-8">
              <TestimonialCarousel />
            </div>
          </motion.section>
        </div>
      </div>
    </>
  );
};

export default Products;
