import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";
import ContactForm from "../components/ContactForm";
import SEO from "../components/SEO";

const Contact: React.FC = () => {
  const { t, language } = useTranslation();

  return (
    <>
      <SEO
        title={t("contact.seo.title")}
        description={t("contact.seo.description")}
        keywords={[
          "Contact",
          "Business Consulting",
          "B2B SaaS",
          "Get in Touch",
        ]}
      />

      <div
        className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-primary mb-4">
              {t("contact.hero.title")}
            </h1>
            <p className="text-xl text-gray-600">
              {t("contact.hero.subtitle")}
            </p>
          </motion.div>

          <motion.div
            className="bg-white rounded-xl shadow-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="flex flex-col md:flex-row">
              <div
                className={`w-full md:w-1/2 p-8 ${
                  language === "ar" ? "md:order-2" : ""
                }`}
              >
                <h2 className="text-2xl font-bold text-primary mb-6">
                  {t("contact.form.title")}
                </h2>
                <ContactForm />
              </div>

              <div
                className={`w-full md:w-1/2 bg-primary/5 p-8 flex items-center justify-center ${
                  language === "ar" ? "md:order-1" : ""
                }`}
              >
                <motion.img
                  src="/images/contact.svg"
                  alt="Contact Illustration"
                  className="max-w-full h-auto max-h-[400px]"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Contact;
