import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";

interface Partner {
  name: string;
  logo: string;
  website: string;
}

const ConsultingPartners: React.FC = () => {
  const { t } = useTranslation();

  // Keep partners data static as these are brand names
  const partners: Partner[] = [
    {
      name: "100 Folds Academy",
      logo: "/images/100Folds_WhiteBG.png",
      website: "https://www.100foldsacademy.com",
    },
    {
      name: "Zuci",
      logo: "/images/zuci_logo.jfif",
      website: "https://online.zuci.in",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <motion.section
      className="py-16 my-8 bg-gradient-to-br from-gray-100 via-white to-gray-50 rounded-2xl shadow-lg"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-primary text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {t("consulting.partners.title")}
        </motion.h2>

        <motion.div
          className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {partners.map((partner) => (
            <motion.div
              key={partner.name}
              variants={itemVariants}
              className="group"
            >
              <motion.a
                href={partner.website}
                target="_blank"
                rel="noopener noreferrer"
                className="block bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
                whileHover={{ y: -5 }}
              >
                <div className="aspect-w-16 aspect-h-9 relative">
                  <motion.img
                    src={partner.logo}
                    alt={`${partner.name} logo`}
                    className="w-full h-full object-contain filter group-hover:brightness-110 transition-all duration-300"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  />
                  <div className="absolute inset-0 bg-primary/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" />
                </div>
                <motion.div className="mt-4 text-center text-primary font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {t("consulting.partners.visitPrefix")} {partner.name}
                </motion.div>
              </motion.a>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ConsultingPartners;
