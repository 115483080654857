import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "../contexts/TranslationContext";

const ConsultingHero: React.FC = () => {
  const { t, language } = useTranslation();

  return (
    <motion.section
      className="py-16 my-8 bg-gradient-to-br from-gray-100 via-white to-gray-50 rounded-2xl shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className="text-center space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-8 relative inline-block">
            {t("consulting.hero.title")}
            <motion.div
              className="absolute bottom-0 left-0 h-1 bg-primary rounded"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.5, duration: 0.8 }}
            />
          </h1>

          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <p className="text-lg md:text-xl text-gray-700 leading-relaxed">
              {t("consulting.hero.intro1")}{" "}
              <a
                href="#cslist"
                className="text-primary font-semibold hover:text-primary/80 transition-colors underline decoration-2 underline-offset-4"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("cslist")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {t("consulting.hero.moreDetails")}
              </a>
            </p>

            <p className="text-lg md:text-xl text-gray-700 leading-relaxed">
              {t("consulting.hero.intro2")}
            </p>
          </motion.div>

          <motion.div
            className="pt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-white bg-primary hover:bg-primary/90 transition-all duration-300 transform hover:scale-105"
            >
              {t("consulting.hero.getStarted")}
              <svg
                className={`${
                  language === "ar" ? "mr-2 -ml-1 rotate-180" : "ml-2 -mr-1"
                } w-5 h-5`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ConsultingHero;
