// src/contexts/TranslationContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import { translations } from "../translations/translations";

type Language = "en" | "ar";

interface TranslationContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
  showToggle: boolean;
}

interface LocationCache {
  countryCode: string;
  timestamp: number;
  isGCC: boolean;
}

const TranslationContext = createContext<TranslationContextType | undefined>(
  undefined
);

const GCC_COUNTRIES = ["BH", "KW", "OM", "QA", "SA", "AE"];
const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>(() => {
    // Initialize language from localStorage if available
    return (localStorage.getItem("preferred-language") as Language) || "en";
  });
  const [showToggle, setShowToggle] = useState(false);

  const getCachedLocation = (): LocationCache | null => {
    const cached = localStorage.getItem("location-cache");
    if (!cached) return null;

    try {
      const parsedCache = JSON.parse(cached) as LocationCache;
      const now = Date.now();

      // Check if cache is expired
      if (now - parsedCache.timestamp > CACHE_DURATION) {
        localStorage.removeItem("location-cache");
        return null;
      }

      return parsedCache;
    } catch (error) {
      localStorage.removeItem("location-cache");
      return null;
    }
  };

  const cacheLocation = (countryCode: string, isGCC: boolean) => {
    const locationCache: LocationCache = {
      countryCode,
      isGCC,
      timestamp: Date.now(),
    };
    localStorage.setItem("location-cache", JSON.stringify(locationCache));
  };

  useEffect(() => {
    const checkLocation = async () => {
      // First check cache
      const cachedLocation = getCachedLocation();

      if (cachedLocation) {
        console.log("Using cached location:", cachedLocation);
        setShowToggle(cachedLocation.isGCC);
        if (
          cachedLocation.isGCC &&
          !localStorage.getItem("preferred-language")
        ) {
          setLanguage("ar");
        }
        return;
      }

      // If no cache, make API call
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();

        if (data.error) {
          console.error("IP API Error:", data.error);
          return;
        }

        const isGCC = GCC_COUNTRIES.includes(data.country_code);

        // Cache the result
        cacheLocation(data.country_code, isGCC);

        setShowToggle(isGCC);
        if (isGCC && !localStorage.getItem("preferred-language")) {
          setLanguage("ar");
        }
      } catch (error) {
        console.error("Error detecting location:", error);
        // Fallback to showing toggle for development
        if (process.env.NODE_ENV === "development") {
          setShowToggle(true);
        }
      }
    };

    checkLocation();
  }, []);

  useEffect(() => {
    document.documentElement.dir = language === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = language;
    localStorage.setItem("preferred-language", language);
  }, [language]);

  const t = (key: string): string => {
    const keys = key.split(".");
    let current: any = translations[language];

    for (const k of keys) {
      if (current?.[k] === undefined) {
        console.warn(`Translation missing for key: ${key}`);
        return key;
      }
      current = current[k];
    }

    return current;
  };

  return (
    <TranslationContext.Provider
      value={{ language, setLanguage, t, showToggle }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
