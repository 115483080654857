import React from "react";
import Slider from "react-slick";
import { useTranslation } from "../contexts/TranslationContext";
import TestimonialCard from "./TestimonialCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./TestimonialCarousel.module.css";

const TestimonialCarousel: React.FC = () => {
  const { t, language } = useTranslation();

  // Get testimonials from translations
  const testimonials = (
    t("products.testimonials.items") as unknown as any[]
  ).map((testimonial: any) => ({
    ...testimonial,
    image:
      testimonial.name === "Bruno Monfils"
        ? "/images/bruno.jpg"
        : "/images/shivi.jpg",
  }));

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    pauseOnHover: true,
    rtl: language === "ar", // Add RTL support for the carousel
    className: `max-w-5xl mx-auto ${styles.sliderCustom}`,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
    ],
    customPaging: (i: number) => (
      <div className="w-3 h-3 mx-1 rounded-full bg-primary/30 hover:bg-primary/50 transition-colors" />
    ),
  };

  return (
    <div className="py-8" dir={language === "ar" ? "rtl" : "ltr"}>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-4 focus:outline-none">
            <TestimonialCard {...testimonial} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
