import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";

const LeadershipTeamHero: React.FC = () => {
  const { t, language } = useTranslation();

  return (
    <motion.section
      className="py-16 my-8 bg-gradient-to-br from-gray-100 via-white to-gray-50 rounded-2xl shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className="text-center space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold text-primary mb-8 relative inline-block">
            {t("leadershipTeam.hero.title")}
            <motion.div
              className="absolute bottom-0 left-0 h-1 bg-primary rounded"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ delay: 0.5, duration: 0.8 }}
            />
          </h1>

          <div className="space-y-6">
            <motion.p
              className="text-lg md:text-xl text-gray-700 leading-relaxed text-justify"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              {t("leadershipTeam.hero.intro1")}
            </motion.p>

            <motion.p
              className="text-lg md:text-xl text-gray-700 leading-relaxed text-justify"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              {t("leadershipTeam.hero.intro2")}
            </motion.p>

            <motion.div
              className="flex justify-center pt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <a
                href="#founder-profiles"
                className="inline-flex items-center gap-2 px-6 py-3 bg-primary text-white rounded-full hover:bg-primary/90 transition-colors duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("founder-profiles")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {t("leadershipTeam.hero.meetFounders")}
                <svg
                  className={`w-5 h-5 ${language === "ar" ? "rotate-180" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default LeadershipTeamHero;
