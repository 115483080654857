//src/components/LanguageToggle.tsx
import React from "react";
import { useTranslation } from "../contexts/TranslationContext";
import { motion } from "framer-motion";

const LanguageToggle: React.FC = () => {
  const { language, setLanguage, showToggle } = useTranslation();

  if (!showToggle) return null;

  const isEnglish = language === "en";

  return (
    <button
      onClick={() => setLanguage(isEnglish ? "ar" : "en")}
      className="relative w-[120px] h-8 rounded-full bg-emphasize p-1 transition-colors duration-200 ease-in-out hover:bg-primary/20 overflow-hidden"
      aria-label={isEnglish ? "Switch to Arabic" : "Switch to English"}
      dir="ltr" // Force LTR layout for consistent sliding behavior
    >
      {/* Static Background */}
      <div className="absolute inset-0 flex items-center">
        <div className="flex-1 font-bold text-center text-sm text-primary/70">
          English
        </div>
        <div className="flex-1 font-bold text-center text-sm text-primary/70">
          عربي
        </div>
      </div>

      {/* Sliding Indicator */}
      <motion.div
        className="absolute top-1 h-6 w-[54px] bg-primary rounded-full"
        initial={{ x: isEnglish ? 2 : 62 }}
        animate={{ x: isEnglish ? 2 : 62 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      />

      {/* Selected Text (Overlay) */}
      <div className="absolute inset-0 flex items-center pointer-events-none">
        <div className="flex-1 font-bold text-center text-sm">
          <span className={isEnglish ? "text-white" : "text-transparent"}>
            English
          </span>
        </div>
        <div className="flex-1 font-bold text-center text-sm">
          <span className={!isEnglish ? "text-white" : "text-transparent"}>
            عربي
          </span>
        </div>
      </div>
    </button>
  );
};

export default LanguageToggle;
