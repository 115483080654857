import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";
import ConsultingHero from "../components/ConsultingHero";
import ConsultingServicesList from "../components/ConsultingServicesList";
import ConsultingTeamSection from "../components/ConsultingTeamSection";
import ConsultingPartners from "../components/ConsultingPartners";
import SEO from "../components/SEO";

const Consulting: React.FC = () => {
  const { t, language } = useTranslation();

  return (
    <>
      <SEO
        title={t("consulting.seo.title")}
        description={t("consulting.seo.description")}
        keywords={[
          "Business Consulting",
          "Operational Excellence",
          "Customer Experience",
          "Organizational Strategy",
          "P&L Management",
          "Analytics",
          "Leadership Development",
        ]}
        schema={{
          "@type": "Service",
          name:
            language === "ar"
              ? "خدمات سايفر ون للاستشارات"
              : "CyfrOne Business Consulting",
          provider: {
            "@type": "Organization",
            name: "CyfrOne",
          },
          serviceType: "Business Consulting",
          description: t("consulting.seo.description"),
          offers: {
            "@type": "Offer",
            description: t("consulting.hero.intro1"),
          },
        }}
      />
      <motion.div
        className="min-h-screen bg-gradient-to-b from-gray-50 to-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        dir={language === "ar" ? "rtl" : "ltr"} // Add RTL support
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <ConsultingHero />
          <ConsultingServicesList />
          <ConsultingTeamSection />
          <ConsultingPartners />
        </div>
      </motion.div>
    </>
  );
};

export default Consulting;
