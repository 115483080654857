import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "../contexts/TranslationContext";

interface SEOProps {
  title: string;
  description: string;
  schema?: object;
  keywords?: string[];
  image?: string;
  type?: string;
  author?: string;
  publishedTime?: string;
  modifiedTime?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  schema,
  keywords = [],
  image = "/images/og-image.jpg",
  type = "website",
  author = "CyfrOne",
  publishedTime,
  modifiedTime,
}) => {
  const { language } = useTranslation();
  // Base organization schema
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "CyfrOne",
    url: "https://cyfrone.com",
    logo: "https://cyfrone.com/images/blue_logo.png",
    description: "B2B SaaS Products and Business Consulting Services",
    sameAs: [
      "https://www.linkedin.com/company/cyfrone/",
      "https://www.youtube.com/@CyfrOne",
    ],
    address: {
      "@type": "PostalAddress",
      addressCountry: "India",
    },
    founders: [
      {
        "@type": "Person",
        name: "Riaz Mohammed",
        jobTitle: "Founder",
      },
      {
        "@type": "Person",
        name: "Tabish Imam",
        jobTitle: "Founder",
      },
    ],
  };

  // Merge custom schema with base schema if provided
  const finalSchema = schema ? { ...baseSchema, ...schema } : baseSchema;

  // Get the canonical URL
  const canonical = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <html lang={language} dir={language === "ar" ? "rtl" : "ltr"} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />
      <meta name="author" content={author} />
      <link rel="canonical" href={canonical} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="CyfrOne" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Article Specific Meta Tags (if type is article) */}
      {type === "article" && (
        <>
          <meta property="article:publisher" content="https://cyfrone.com" />
          {publishedTime && (
            <meta property="article:published_time" content={publishedTime} />
          )}
          {modifiedTime && (
            <meta property="article:modified_time" content={modifiedTime} />
          )}
          <meta property="article:author" content={author} />
        </>
      )}

      {/* Robots Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />

      {/* Additional Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="theme-color" content="#0054a6" />

      {/* JSON-LD Schema */}
      <script type="application/ld+json">{JSON.stringify(finalSchema)}</script>
    </Helmet>
  );
};

export default SEO;
