// src/translations/translations.ts
export const translations = {
  en: {
    navigation: {
      aboutUs: "About Us",
      leadershipTeam: "Leadership Team",
      products: "Products",
      consulting: "Consulting",
      blogs: "Blogs",
      contactUs: "Contact Us",
    },
    carousel: {
      vision: {
        title: "Our Vision",
        content:
          "To build enduring solutions for deep rooted business problems from the first principles.",
      },
      mission: {
        title: "Our Mission",
        content:
          "To Provide solutions by leveraging technology & leadership experience for businesses to raise the bar on customer experience, unlock hidden value and realize true potential.",
      },
    },
    about: {
      seo: {
        title: "CyfrOne - B2B SaaS Products and Business Consulting",
        description:
          "We build B2B SaaS products and provide expert business consulting services.",
        keywords: [
          "B2B SaaS Products",
          "Business Consulting",
          "Restaturant Management System",
          "Operational Excellence",
          "Executive Coaching",
        ],
      },
      hero: {
        title: "Transform Your Business",
        withText: "with",
        description:
          "We build innovative solutions for deep-rooted business problems using first principles methodology.",
        getStarted: "Get Started",
        ourSolutions: "Our Solutions",
      },
      features: {
        zeroToOne: "From Zero to One Philosophy",
        firstPrinciples: "First Principles Thinking",
        transformation: "Business Transformation",
        innovation: "Continuous Innovation",
      },
      aboutSection: {
        title: "About CyfrOne",
        paragraph1:
          "CyfrOne is built on the philosophy of moving from Zero to One, representing a significant leap from nothing to something remarkable. This concept drives our mission to create solutions that are innovative and built to last.",
        paragraph2:
          'Our name, combining "Cypher" (Zero) and "One," symbolizes this journey from inception to transformation. We are catalysts for significant change, committed to addressing complex business challenges across industries.',
        paragraph3:
          "Our foundation is rooted in first principles thinking, allowing us to approach each problem with fresh perspectives and novel approaches. By leveraging cutting-edge technology and strategic insights, we strive to shape the future of business transformation.",
      },
      foundersStory: {
        title: "The Founders' Story",
        paragraph1:
          "CyfrOne, founded in 2023 by Riaz Mohammed and Tabish Imam, was inspired by their shared experiences in tackling complex, large-scale challenges across industries.",
        paragraph2:
          "Recognizing the need for innovative, multifaceted solutions to address root causes of persistent business problems, they developed CyfrOne's distinctive multi-pronged approach.",
        viewProfiles: "View Founder Profiles",
        imageAlt: "Founders of CyfrOne - Riaz Mohammed and Tabish Imam",
      },
      approaches: {
        title: "Our Approach to Solving Industry Problems",
        intro:
          "At CyfrOne, we recognize that each industry faces unique challenges. Our multi-pronged solution model is tailored to address broader as well as specific needs and gaps within each industry.",
        conclusion:
          "We also realize 'no one-size-fits-all' answer to complex business problems, hence we build bespoke solutions to align with each client's specific needs and goals. At CyfrOne, we're partners in your journey towards operational excellence and business transformation, continually evolving our approach to meet the changing needs of industries.",
        items: [
          {
            name: "B2B SaaS Products",
            desc: "We develop cutting-edge B2B SaaS products designed to streamline operations, enhance efficiency, and drive innovation within specific industries. Our scalable solutions offer flexibility, foster collaboration, and enable data-driven decision making. With robust integration capabilities and continuous updates, our platforms evolve with your business needs, ensuring long-term value and competitiveness.",
            action: "View our Products",
          },
          {
            name: "Business Consulting",
            desc: "Our experienced team delivers strategic insights and operational expertise across key areas: operational excellence, customer experience, organizational strategy, P&L management, and analytics. We help businesses optimize processes, enhance decision-making, and achieve sustainable growth. By leveraging data-driven approaches, we empower organizations to transform their operations and stay competitive in dynamic markets.",
            action: "View Our Consulting Offerings",
          },
          {
            name: "Executive Coaching",
            desc: "We offer tailored coaching programs for C-suite executives and senior leadership, designed to elevate both individual and organizational competence. By blending leadership theory with industry insights, we enhance strategic thinking and change management capabilities, empowering leaders to navigate complex challenges and drive innovation in today's dynamic business landscape.",
            action: "Connect with Us Now",
          },
        ],
      },
      cta: {
        title: "Ready to Transform Your Business?",
        description:
          "Join hands with CyfrOne to unlock your business's true potential. Let's create innovative solutions together.",
        consultation: "Schedule a Consultation",
        explore: "Explore Our Products",
      },
    },
    leadershipTeam: {
      seo: {
        title: "Leadership Team | CyfrOne - Experienced Business Leaders",
        description:
          "Meet CyfrOne's leadership team - seasoned professionals with 35+ years of combined experience from Amazon, Bank of America, and Tata Steel, driving innovation in business transformation.",
        keywords: [
          "Leadership Team",
          "Riaz Mohammed",
          "Tabish Imam",
          "Business Leaders",
          "CyfrOne Management",
        ],
      },
      hero: {
        title: "Our Leadership Team",
        intro1:
          "CyfrOne's success is driven by seasoned professionals with over three and half decades of combined experience across various industries, including leaders like Amazon, Bank of America, and Tata Steel. Our co-founders, Riaz Mohammed and Tabish Imam, bring a wealth of knowledge in global operations, finance, technology, and strategy.",
        intro2:
          "They have a proven track record of building high-performing teams and delivering exceptional results. Passionate about solving complex problems and driving innovation, Riaz and Tabish are committed to creating transformative business solutions and delivering lasting value for our clients through CyfrOne's distinctive approach.",
        meetFounders: "Meet Our Founders",
      },
      founders: {
        sectionTitle: "Meet Our Founders",
        founderProfiles: [
          {
            name: "Riaz Mohammed",
            title: "Co-founder",
            bio: [
              "Riaz brings over 20 years of global leadership experience in Operations, Programs, and Entrepreneurship with Amazon, Bank of America, and General Electric. He has developed expertise in E-Commerce, Supply Chain, Tech Support, Customer Service, and Offshore business. Most recently, he held an executive position at Amazon, leading World Wide Operations, Programs, Workforce Management, Analytics, Quality, and Training functions in the Middle Mile for Amazon Transportation Services.",
              "Riaz began his career as an entrepreneur after earning an MBA in Finance from Osmania University in 2002, becoming an Airtel Franchisee partner with Bharti Group. He is recognized for his thought leadership, long-term vision, and ability to build and lead global teams. He has managed large-scale World Wide teams, successfully owned P&Ls, and served as an executive Bar Raiser and Diversity champion at Amazon.",
              "A builder at heart, Riaz believes in disruptive thinking for solving problems and has a passion for Organizational and Leadership development. His affinity for travel has enhanced his cultural intelligence, making him a highly effective global leader.",
            ],
            expertise: [
              "Global Operations",
              "Strategic Leadership",
              "Business Development",
              "Team Building",
            ],
          },
          {
            name: "Tabish Imam",
            title: "Co-founder",
            bio: [
              "Tabish, an IIT Kharagpur and IIM Bangalore alumnus, began his career with a technology consulting startup, iRunway, where he provided technical expertise on marquee patent litigation and portfolio analysis projects. He then moved to Tata Steel Ltd, where he led IT supply chain expansion projects and gained valuable experience in core operations and lean manufacturing principles.",
              "After completing his MBA in Strategy & Operations from IIM Bangalore, Tabish joined the Amazon Pathways Operations Leadership Program. He initially led the site expansion for Amazon Devices and Digital products in Hyderabad, and later managed the expansion of Shipping & Delivery Support teams across India and the Philippines. His work at Amazon focused on enhancing operational efficiency and customer experience.",
              "Throughout his career, Tabish has built high-performing teams and developed leaders, leveraging his critical and analytical thinking skills to solve complex problems and deliver sustainable results. Passionate about technology, problem-solving, leadership development, learning new skills, and sports, he consistently strives for excellence in all his endeavors.",
            ],
            expertise: [
              "Technology Consulting",
              "Operations Strategy",
              "Process Optimization",
              "Leadership Development",
            ],
          },
        ],
      },
    },
    consulting: {
      seo: {
        title: "Business Consulting Services | CyfrOne",
        description:
          "Transform your business with CyfrOne's expert consulting services in Operational Excellence, Customer Experience, Strategy, P&L Management, Analytics, and Leadership Development.",
      },
      hero: {
        title: "Business Consulting",
        intro1:
          "CyfrOne offers Business Consulting services focused on Operational Excellence, Customer Experience, Organizational Strategy, P&L Management, Analytics, and Executive Leadership development.",
        moreDetails: "More details below...",
        intro2:
          "Our Strategy through these consulting assignments will be to use first principles methodology and work backwards from the end-customer. While the team carries diverse experience and expertise across industries, we also believe that fundamental methods of problem solving are key to address core problems for any industry or organization.",
        getStarted: "Get Started",
      },
      services: {
        title: "Our Consulting Services",
        items: [
          {
            title: "Operational Excellence",
            description:
              "Streamline operations to enhance efficiency and reduce costs. Our team of experts will work with you to identify inefficiencies, develop streamlined processes, and implement best practices that drive operational excellence. By analyzing your current operations, we can uncover hidden opportunities for improvement and implement solutions that enhance productivity and reduce operational costs.",
            icon: "⚙️",
            bgColor: "bg-blue-50",
          },
          {
            title: "Customer Experience",
            description:
              "Develop strategies to improve customer satisfaction and loyalty. We understand that exceptional customer experience is key to success, and our solutions are designed to help you exceed customer expectations at every touchpoint. Our approach involves analyzing customer feedback, understanding their needs, and designing strategies that enhance their overall experience with your brand.",
            icon: "😊",
            bgColor: "bg-green-50",
          },
          {
            title: "Organizational Strategy",
            description:
              "Craft robust strategies that align with your business goals. Our strategic planning services will help you define clear objectives, develop actionable plans, and achieve sustainable growth. We work closely with your leadership team to understand your vision and create a roadmap that drives your organization towards its goals.",
            icon: "🎯",
            bgColor: "bg-purple-50",
          },
          {
            title: "P&L Management",
            description:
              "Optimize your profit and loss management for better financial performance. We provide insights and tools to help you manage your finances effectively, ensuring profitability and long-term success. Our P&L management services include budgeting, forecasting, and financial analysis to help you make informed decisions that drive profitability.",
            icon: "💰",
            bgColor: "bg-yellow-50",
          },
          {
            title: "Analytics",
            description:
              "Leverage data to gain insights and drive informed decision-making. Our analytics services provide you with the information you need to understand your business performance and make data-driven decisions. From customer behavior analysis to operational metrics, we offer comprehensive analytics solutions that help you uncover trends and identify areas for improvement.",
            icon: "📊",
            bgColor: "bg-red-50",
          },
          {
            title: "Executive Leadership Development",
            description:
              "Cultivate leadership skills and capabilities within your organization. We offer leadership development programs that are tailored to your needs, helping you build a strong leadership team that drives success. Our programs focus on developing the skills and competencies needed to lead effectively in today's complex business environment.",
            icon: "👥",
            bgColor: "bg-indigo-50",
          },
        ],
      },
      team: {
        title: "Our Principal Consulting Partners",
        consultNow: "Consult Now",
        members: [
          {
            name: "Riazuddin Mohammed",
            expertise: "Strategy & Operations",
            designation: "CoFounder, Sr. Principal Consultant",
            bio: "20+ years of global leadership experience in Operations and Programs",
          },
          {
            name: "Tabish Imam",
            expertise: "Technology & Innovation",
            designation: "CoFounder, Sr. Principal Consultant",
            bio: "IIT & IIM alumnus with expertise in technology consulting and operations",
          },
        ],
      },
      partners: {
        title: "Our Past Consulting Assignments",
        visitPrefix: "Visit",
      },
    },
    products: {
      seo: {
        title: "OASIS - Restaurant Management SaaS | CyfrOne Products",
        description:
          "Discover OASIS (One-Stop Advanced System for Integrated Solutions) - CyfrOne's comprehensive restaurant management SaaS platform. Maximize sales, optimize costs, and enhance customer experience.",
        schema: {
          name: "CyfrOne OASIS",
          description:
            "One-Stop Advanced System for Integrated Solutions for Restaurant Management",
        },
      },
      hero: {
        title: "Introducing OASIS",
        intro1:
          "CyfrOne OASIS (One-Stop Advanced System for Integrated Solutions) is an integrated Restaurant Management SaaS that addresses the core industry problems to maximize sales and optimize costs across the value chain of restaurants and cafes.",
        intro2:
          "The product focuses on the business owner's perspective enhancing customer experience, reducing people dependency and streamlining processes along with generating intelligent and actionable insights for different user groups of the product.",
      },
      features: {
        title: "Key Features",
        items: [
          {
            title: "Streamlined Operations",
            description:
              "Optimize your entire restaurant workflow from order management to inventory control",
            icon: "🔄",
          },
          {
            title: "Enhanced Customer Experience",
            description:
              "Provide seamless ordering and personalized service to your customers",
            icon: "⭐",
          },
          {
            title: "Intelligent Insights",
            description:
              "Make data-driven decisions with comprehensive analytics and reporting",
            icon: "📊",
          },
        ],
      },
      testimonials: {
        title: "What Industry Leaders Say",
        items: [
          {
            name: "Bruno Monfils",
            title: "COO, TadaWeb, Luxembourg",
            quote:
              "CyfrOne's solution enables hotels, restaurants and catering owners to focus on the customer experience by removing inefficiencies in the entire value chain, just with a couple of clicks. I am impressed with the simplicity of the integrated solution, which improves efficiency, aids in decision making with analytical insights and reduces food waste from ordering to inventory management. As a CEO or HoReCa owners, it feels like CyfrOne gives you access to the ultimate seamless, individual food ordering.",
          },
          {
            name: "Shivi Shankaran",
            title: "COO, Saks OFF 5th, USA",
            quote:
              "Having personally inspected Cyfrone's innovative solution, I believe it will have a transformative impact on the hospitality industry. I am impressed by the platform's ability to make it simpler for both operators and customers. I found that its intuitive design makes eliminating inefficiencies in the value chain a breeze, requiring just a few clicks. What sets Cyfrone apart is its impact on decision-making through analytical insights from order placement to inventory management. For customers, Cyfrone OASIS has become an invaluable asset, offering a seamless and personalized food ordering experience. It's a game-changer that I personally endorse, recognizing its crucial role in supporting the specific needs of restaurants and hospitality establishments.",
          },
        ],
      },
    },
    blogs: {
      seo: {
        title: "CyfrOne Blog - Insights on Business & Technology",
        description:
          "Explore CyfrOne's latest insights on business transformation, technology innovation, and industry trends.",
      },
      hero: {
        title: "Our Latest Insights",
        subtitle:
          "Discover our thoughts on business transformation, technology innovation, and industry trends.",
      },
      pagination: {
        previous: "Previous",
        next: "Next",
      },
      notFound: {
        title: "Blog Not Found",
        backToBlog: "Back to Blogs",
      },
      blogDetail: {
        tableOfContents: "Table of Contents",
        share: "Share This Post",
        relatedPosts: "Related Posts",
        backToBlog: "Back to Blogs",
      },
      shareLinks: {
        linkedin: "Share on LinkedIn",
        twitter: "Share on Twitter",
        facebook: "Share on Facebook",
        instagram: "Share on Instagram",
        whatsapp: "Share on WhatsApp",
      },
    },
    contact: {
      seo: {
        title: "Contact Us | CyfrOne",
        description:
          "Get in touch with CyfrOne for B2B SaaS solutions and business consulting services. We're here to help transform your business.",
      },
      hero: {
        title: "Let's Connect",
        subtitle:
          "We would love to hear from you and help transform your business",
      },
      form: {
        title: "Send Us a Message",
        fields: {
          name: "Your Name",
          email: "Your Email",
          phone: "Phone Number (Optional)",
          subject: "Subject",
          message: "Your Message",
          purpose: {
            label: "Purpose of Contact",
            options: {
              consulting: "Business Consulting",
              products: "Products",
              oasis: "Products - OASIS",
              coaching: "Executive Coaching",
              others: "Others",
            },
          },
        },
        button: {
          send: "Send Message",
          sending: "Sending Message...",
        },
        messages: {
          success: "Message sent successfully! We'll get back to you soon.",
          error: "Failed to send the message. Please try again later.",
        },
      },
    },
    whatsapp: {
      title: "Chat with us on WhatsApp",
      buttonText: "Chat with us",
    },
    // Add more translations as needed
  },
  ar: {
    navigation: {
      aboutUs: "من نحن",
      leadershipTeam: "فريق القيادة",
      products: "المنتجات",
      consulting: "الاستشارات",
      blogs: "المدونة",
      contactUs: "اتصل بنا",
    },
    carousel: {
      vision: {
        title: "رؤيتنا",
        content:
          "بناء حلول دائمة للمشكلات التجارية المتجذرة من المبادئ الأولى.",
      },
      mission: {
        title: "مهمتنا",
        content:
          "تقديم حلول من خلال الاستفادة من التكنولوجيا وخبرة القيادة للشركات لرفع مستوى تجربة العملاء، وفتح القيمة المخفية وتحقيق الإمكانات الحقيقية.",
      },
    },
    about: {
      seo: {
        title: "صِفرون - منتجات B2B SaaS واستشارات الأعمال",
        description:
          "نقوم ببناء منتجات B2B SaaS وتقديم خدمات استشارات الأعمال المتخصصة.",
        keywords: [
          "منتجات B2B SaaS",
          "استشارات الأعمال",
          "نظام إدارة المطاعم",
          "التميز التشغيلي",
          "التدريب التنفيذي",
        ],
      },
      hero: {
        title: "حوّل أعمالك",
        withText: "مع",
        description:
          "نبني حلولاً مبتكرة للمشكلات التجارية المتجذرة باستخدام منهجية المبادئ الأولى.",
        getStarted: "ابدأ الآن",
        ourSolutions: "حلولنا",
      },
      features: {
        zeroToOne: "فلسفة من الصفر إلى واحد",
        firstPrinciples: "التفكير بالمبادئ الأولى",
        transformation: "تحول الأعمال",
        innovation: "الابتكار المستمر",
      },
      aboutSection: {
        title: "عن صِفرون",
        paragraph1:
          "تم بناء صِفرون على فلسفة الانتقال من الصفر إلى واحد، وهو ما يمثل قفزة كبيرة من لا شيء إلى شيء متميز. هذا المفهوم يقود مهمتنا في إنشاء حلول مبتكرة ومبنية لتدوم.",
        paragraph2:
          "يرمز اسمنا، الذي يجمع بين 'سايفر' (صفر) و'ون'، إلى هذه الرحلة من البداية إلى التحول. نحن محفزون للتغيير الكبير، ملتزمون بمعالجة تحديات الأعمال المعقدة عبر الصناعات.",
        paragraph3:
          "يتجذر أساسنا في التفكير بالمبادئ الأولى، مما يتيح لنا مقاربة كل مشكلة بوجهات نظر جديدة ونهج مبتكر. من خلال الاستفادة من التكنولوجيا المتطورة والرؤى الاستراتيجية، نسعى لتشكيل مستقبل تحول الأعمال.",
      },
      foundersStory: {
        title: "قصة المؤسسين",
        paragraph1:
          "تأسست صِفرون في عام 2023 على يد رياض محمد وطبيش إمام، مستوحاة من تجاربهم المشتركة في معالجة التحديات المعقدة واسعة النطاق عبر الصناعات.",
        paragraph2:
          "إدراكاً للحاجة إلى حلول مبتكرة ومتعددة الأوجه لمعالجة الأسباب الجذرية لمشكلات الأعمال المستمرة، طوروا نهج صِفرون المميز متعدد الجوانب.",
        viewProfiles: "عرض الملفات الشخصية للمؤسسين",
        imageAlt: "مؤسسو صِفرون - رياض محمد وطبيش إمام",
      },
      approaches: {
        title: "نهجنا في حل مشكلات الصناعة",
        intro:
          "في صِفرون، ندرك أن كل صناعة تواجه تحديات فريدة. نموذج حلولنا متعدد الجوانب مصمم خصيصاً لتلبية الاحتياجات والفجوات الأوسع والمحددة داخل كل صناعة.",
        conclusion:
          "نحن ندرك أيضاً أنه لا يوجد حل واحد يناسب الجميع للمشكلات التجارية المعقدة، لذلك نبني حلولاً مخصصة تتوافق مع احتياجات وأهداف كل عميل. في صِفرون، نحن شركاء في رحلتك نحو التميز التشغيلي وتحول الأعمال، نتطور باستمرار لتلبية الاحتياجات المتغيرة للصناعات.",
        items: [
          {
            name: "منتجات B2B SaaS",
            desc: "نطور منتجات B2B SaaS متطورة مصممة لتبسيط العمليات وتعزيز الكفاءة ودفع الابتكار داخل صناعات محددة. توفر حلولنا القابلة للتطوير المرونة وتعزز التعاون وتمكّن من اتخاذ قرارات مبنية على البيانات. مع قدرات التكامل القوية والتحديثات المستمرة، تتطور منصاتنا مع احتياجات عملك، مما يضمن قيمة وتنافسية طويلة الأمد.",
            action: "عرض منتجاتنا",
          },
          {
            name: "الاستشارات التجارية",
            desc: "يقدم فريقنا المتمرس رؤى استراتيجية وخبرة تشغيلية في المجالات الرئيسية: التميز التشغيلي، تجربة العملاء، الاستراتيجية التنظيمية، إدارة الأرباح والخسائر، والتحليلات. نساعد الشركات على تحسين العمليات وتعزيز صنع القرار وتحقيق النمو المستدام. من خلال الاستفادة من النهج القائم على البيانات، نمكّن المؤسسات من تحويل عملياتها والبقاء تنافسية في الأسواق الديناميكية.",
            action: "عرض خدماتنا الاستشارية",
          },
          {
            name: "التدريب التنفيذي",
            desc: "نقدم برامج تدريب مخصصة للمديرين التنفيذيين والقيادة العليا، مصممة لرفع مستوى الكفاءة الفردية والتنظيمية. من خلال الجمع بين نظرية القيادة ورؤى الصناعة، نعزز التفكير الاستراتيجي وقدرات إدارة التغيير، مما يمكّن القادة من التنقل في التحديات المعقدة ودفع الابتكار في مشهد الأعمال الديناميكي اليوم.",
            action: "تواصل معنا الآن",
          },
        ],
      },
      cta: {
        title: "هل أنت مستعد لتحويل عملك؟",
        description:
          "انضم إلى صِفرون لإطلاق الإمكانات الحقيقية لعملك. دعنا نبتكر حلولاً معاً.",
        consultation: "جدولة استشارة",
        explore: "استكشف منتجاتنا",
      },
    },
    leadershipTeam: {
      seo: {
        title: "فريق القيادة | صِفرون - قادة أعمال ذوو خبرة",
        description:
          "تعرف على فريق قيادة صِفرون - محترفون ذوو خبرة تزيد عن 35 عامًا من الخبرة المشتركة من أمازون وبنك أوف أمريكا وتاتا ستيل، يقودون الابتكار في تحول الأعمال.",
        keywords: [
          "فريق القيادة",
          "رياض محمد",
          "طبيش إمام",
          "قادة الأعمال",
          "إدارة صِفرون",
        ],
      },
      hero: {
        title: "فريق القيادة",
        intro1:
          "يقود نجاح صِفرون محترفون ذوو خبرة تزيد عن ثلاثة عقود ونصف من الخبرة المشتركة عبر مختلف الصناعات، بما في ذلك قادة من أمازون وبنك أوف أمريكا وتاتا ستيل. يجلب مؤسسونا المشاركون، رياض محمد وطبيش إمام، ثروة من المعرفة في العمليات العالمية والمالية والتكنولوجيا والاستراتيجية.",
        intro2:
          "لديهم سجل حافل في بناء فرق عالية الأداء وتحقيق نتائج استثنائية. بشغفهم لحل المشكلات المعقدة وقيادة الابتكار، يلتزم رياض وطبيش بإنشاء حلول أعمال تحويلية وتقديم قيمة دائمة لعملائنا من خلال نهج صِفرون المميز.",
        meetFounders: "تعرف على مؤسسينا",
      },
      founders: {
        sectionTitle: "تعرف على مؤسسينا",
        founderProfiles: [
          {
            name: "رياض محمد",
            title: "المؤسس المشارك",
            bio: [
              "يتمتع رياض بخبرة تزيد عن 20 عامًا في القيادة العالمية في مجالات العمليات والبرامج وريادة الأعمال مع أمازون وبنك أوف أمريكا وجنرال إلكتريك. لقد طور خبرة في التجارة الإلكترونية وسلسلة التوريد والدعم التقني وخدمة العملاء والأعمال الخارجية.",
              "بدأ رياض حياته المهنية كرائد أعمال بعد حصوله على ماجستير إدارة الأعمال في المالية من جامعة عثمانيا في عام 2002، حيث أصبح شريكًا امتياز لشركة إيرتل مع مجموعة بهارتي. وهو معروف بقيادته الفكرية ورؤيته طويلة المدى وقدرته على بناء وقيادة الفرق العالمية.",
              "كونه مبتكرًا في صميمه، يؤمن رياض بالتفكير المبتكر لحل المشكلات ولديه شغف بالتطوير التنظيمي والقيادي. لقد عزز شغفه بالسفر من ذكائه الثقافي، مما جعله قائدًا عالميًا عالي الفعالية.",
            ],
            expertise: [
              "العمليات العالمية",
              "القيادة الاستراتيجية",
              "تطوير الأعمال",
              "بناء الفريق",
            ],
          },
          {
            name: "طبيش إمام",
            title: "المؤسس المشارك",
            bio: [
              "بدأ طبيش، خريج IIT خاراغبور وIIM بنغالور، حياته المهنية مع شركة ناشئة للاستشارات التكنولوجية، iRunway، حيث قدم خبرة تقنية في مشاريع التقاضي وتحليل المحافظ البارزة. ثم انتقل إلى شركة تاتا ستيل المحدودة، حيث قاد مشاريع توسيع سلسلة التوريد لتكنولوجيا المعلومات واكتسب خبرة قيمة في العمليات الأساسية ومبادئ التصنيع الرشيق.",
              "بعد إكمال درجة الماجستير في إدارة الأعمال في الاستراتيجية والعمليات من IIM بنغالور، انضم طبيش إلى برنامج قيادة العمليات في أمازون باثوايز. قاد في البداية توسع موقع أجهزة ومنتجات أمازون الرقمية في حيدر أباد، وأدار لاحقًا توسع فرق دعم الشحن والتسليم في الهند والفلبين.",
              "طوال حياته المهنية، قام طبيش ببناء فرق عالية الأداء وتطوير القادة، مستفيدًا من مهاراته في التفكير النقدي والتحليلي لحل المشكلات المعقدة وتحقيق نتائج مستدامة. شغوف بالتكنولوجيا وحل المشكلات وتطوير القيادة وتعلم مهارات جديدة والرياضة.",
            ],
            expertise: [
              "الاستشارات التكنولوجية",
              "استراتيجية العمليات",
              "تحسين العمليات",
              "تطوير القيادة",
            ],
          },
        ],
      },
    },
    consulting: {
      seo: {
        title: "خدمات الاستشارات التجارية | صِفرون",
        description:
          "حول أعمالك مع خدمات صِفرون الاستشارية المتخصصة في التميز التشغيلي، تجربة العملاء، الاستراتيجية، إدارة الأرباح والخسائر، التحليلات، وتطوير القيادة.",
      },
      hero: {
        title: "الاستشارات التجارية",
        intro1:
          "تقدم صِفرون خدمات استشارية تركز على التميز التشغيلي، تجربة العملاء، الاستراتيجية التنظيمية، إدارة الأرباح والخسائر، التحليلات، وتطوير القيادة التنفيذية.",
        moreDetails: "المزيد من التفاصيل أدناه...",
        intro2:
          "استراتيجيتنا في هذه المهام الاستشارية ستكون استخدام منهجية المبادئ الأولى والعمل بشكل عكسي من العميل النهائي. بينما يحمل الفريق خبرة متنوعة عبر الصناعات، نؤمن أيضاً أن الأساليب الأساسية لحل المشكلات هي المفتاح لمعالجة المشكلات الأساسية لأي صناعة أو منظمة.",
        getStarted: "ابدأ الآن",
      },
      services: {
        title: "خدماتنا الاستشارية",
        items: [
          {
            title: "التميز التشغيلي",
            description:
              "تبسيط العمليات لتحسين الكفاءة وتقليل التكاليف. سيعمل فريق خبرائنا معك لتحديد نقاط عدم الكفاءة، وتطوير عمليات مبسطة، وتنفيذ أفضل الممارسات التي تدفع التميز التشغيلي. من خلال تحليل عملياتك الحالية، يمكننا اكتشاف فرص التحسين المخفية وتنفيذ حلول تعزز الإنتاجية وتقلل تكاليف التشغيل.",
            icon: "⚙️",
            bgColor: "bg-blue-50",
          },
          {
            title: "تجربة العملاء",
            description:
              "تطوير استراتيجيات لتحسين رضا العملاء وولائهم. نحن نفهم أن تجربة العملاء الاستثنائية هي مفتاح النجاح، وحلولنا مصممة لمساعدتك على تجاوز توقعات العملاء في كل نقطة اتصال. يتضمن نهجنا تحليل تعليقات العملاء، وفهم احتياجاتهم، وتصميم استراتيجيات تعزز تجربتهم الشاملة مع علامتك التجارية.",
            icon: "😊",
            bgColor: "bg-green-50",
          },
          {
            title: "الاستراتيجية التنظيمية",
            description:
              "صياغة استراتيجيات قوية تتوافق مع أهداف عملك. ستساعدك خدمات التخطيط الاستراتيجي لدينا في تحديد أهداف واضحة، وتطوير خطط قابلة للتنفيذ، وتحقيق نمو مستدام. نعمل عن كثب مع فريق القيادة لديك لفهم رؤيتك وإنشاء خارطة طريق تدفع منظمتك نحو أهدافها.",
            icon: "🎯",
            bgColor: "bg-purple-50",
          },
          {
            title: "إدارة الأرباح والخسائر",
            description:
              "تحسين إدارة الأرباح والخسائر لتحسين الأداء المالي. نقدم رؤى وأدوات لمساعدتك في إدارة أموالك بفعالية، مما يضمن الربحية والنجاح على المدى الطويل. تشمل خدمات إدارة الأرباح والخسائر لدينا الميزانية والتنبؤ والتحليل المالي لمساعدتك في اتخاذ قرارات مدروسة تدفع الربحية.",
            icon: "💰",
            bgColor: "bg-yellow-50",
          },
          {
            title: "التحليلات",
            description:
              "الاستفادة من البيانات لاكتساب رؤى وتوجيه صنع القرار المدروس. توفر لك خدمات التحليلات لدينا المعلومات التي تحتاجها لفهم أداء عملك واتخاذ قرارات مبنية على البيانات. من تحليل سلوك العملاء إلى مقاييس التشغيل، نقدم حلول تحليلات شاملة تساعدك في اكتشاف الاتجاهات وتحديد مجالات التحسين.",
            icon: "📊",
            bgColor: "bg-red-50",
          },
          {
            title: "تطوير القيادة التنفيذية",
            description:
              "تنمية المهارات والقدرات القيادية داخل مؤسستك. نقدم برامج تطوير قيادية مصممة حسب احتياجاتك، مما يساعدك في بناء فريق قيادي قوي يدفع النجاح. تركز برامجنا على تطوير المهارات والكفاءات اللازمة للقيادة بفعالية في بيئة الأعمال المعقدة اليوم.",
            icon: "👥",
            bgColor: "bg-indigo-50",
          },
        ],
      },
      team: {
        title: "شركاؤنا الاستشاريون الرئيسيون",
        consultNow: "استشر الآن",
        members: [
          {
            name: "رياض محمد",
            expertise: "الاستراتيجية والعمليات",
            designation: "المؤسس المشارك، كبير المستشارين",
            bio: "خبرة أكثر من 20 عاماً في القيادة العالمية في العمليات والبرامج",
          },
          {
            name: "طبيش إمام",
            expertise: "التكنولوجيا والابتكار",
            designation: "المؤسس المشارك، كبير المستشارين",
            bio: "خريج IIT و IIM مع خبرة في الاستشارات التكنولوجية والعمليات",
          },
        ],
      },
      partners: {
        title: "مهامنا الاستشارية السابقة",
        visitPrefix: "زيارة",
      },
    },
    products: {
      seo: {
        title: "OASIS - نظام إدارة المطاعم السحابي | منتجات صِفرون",
        description:
          "اكتشف OASIS (النظام المتكامل المتقدم للحلول المتكاملة) - منصة صِفرون الشاملة لإدارة المطاعم. زيادة المبيعات، تحسين التكاليف، وتعزيز تجربة العملاء.",
        schema: {
          name: "صِفرون OASIS",
          description:
            "النظام المتكامل المتقدم للحلول المتكاملة لإدارة المطاعم",
        },
      },
      hero: {
        title: "نقدم لكم OASIS",
        intro1:
          "صِفرون OASIS (النظام المتكامل المتقدم للحلول المتكاملة) هو نظام إدارة مطاعم متكامل يعالج مشاكل الصناعة الأساسية لزيادة المبيعات وتحسين التكاليف عبر سلسلة القيمة للمطاعم والمقاهي.",
        intro2:
          "يركز المنتج على وجهة نظر صاحب العمل في تحسين تجربة العملاء، وتقليل الاعتماد على الأفراد وتبسيط العمليات مع توليد رؤى ذكية وقابلة للتنفيذ لمختلف مجموعات المستخدمين للمنتج.",
      },
      features: {
        title: "الميزات الرئيسية",
        items: [
          {
            title: "عمليات منظمة",
            description:
              "تحسين سير عمل مطعمك بالكامل من إدارة الطلبات إلى مراقبة المخزون",
            icon: "🔄",
          },
          {
            title: "تجربة عملاء محسنة",
            description: "تقديم خدمة طلبات سلسة وشخصية لعملائك",
            icon: "⭐",
          },
          {
            title: "رؤى ذكية",
            description:
              "اتخاذ قرارات مبنية على البيانات مع تحليلات وتقارير شاملة",
            icon: "📊",
          },
        ],
      },
      testimonials: {
        title: "ماذا يقول قادة الصناعة",
        items: [
          {
            name: "برونو مونفيلس",
            title: "المدير التنفيذي للعمليات، تاداويب، لوكسمبورغ",
            quote:
              "يمكّن حل صِفرون أصحاب الفنادق والمطاعم وخدمات الطعام من التركيز على تجربة العملاء من خلال إزالة عدم الكفاءة في سلسلة القيمة بأكملها، بمجرد نقرات قليلة. أنا معجب ببساطة الحل المتكامل، الذي يحسن الكفاءة، ويساعد في اتخاذ القرار برؤى تحليلية ويقلل من هدر الطعام من الطلب إلى إدارة المخزون. كمدير تنفيذي أو مالك في قطاع الضيافة، يشعر المرء وكأن صِفرون يمنحك الوصول إلى طلب الطعام الفردي السلس النهائي.",
          },
          {
            name: "شيفي شانكاران",
            title: "المدير التنفيذي للعمليات، ساكس أوف فيفث، الولايات المتحدة",
            quote:
              "بعد فحص حل صِفرون المبتكر شخصياً، أعتقد أنه سيكون له تأثير تحويلي على صناعة الضيافة. أنا معجب بقدرة المنصة على جعل الأمور أبسط لكل من المشغلين والعملاء. وجدت أن تصميمه البديهي يجعل القضاء على عدم الكفاءة في سلسلة القيمة أمراً سهلاً، يتطلب بضع نقرات فقط. ما يميز صِفرون هو تأثيره على صنع القرار من خلال الرؤى التحليلية من تقديم الطلب إلى إدارة المخزون. بالنسبة للعملاء، أصبح OASIS من صِفرون أصلاً لا يقدر بثمن، يقدم تجربة طلب طعام سلسة وشخصية. إنه تغيير جذري أؤيده شخصياً، معترفاً بدوره الحاسم في دعم الاحتياجات المحددة للمطاعم ومنشآت الضيافة.",
          },
        ],
      },
    },
    blogs: {
      seo: {
        title: "مدونة صِفرون - رؤى في الأعمال والتكنولوجيا",
        description:
          "اكتشف أحدث رؤى صِفرون حول تحول الأعمال والابتكار التكنولوجي واتجاهات الصناعة.",
      },
      hero: {
        title: "أحدث رؤانا",
        subtitle:
          "اكتشف أفكارنا حول تحول الأعمال والابتكار التكنولوجي واتجاهات الصناعة.",
      },
      pagination: {
        previous: "السابق",
        next: "التالي",
      },
      notFound: {
        title: "المقال غير موجود",
        backToBlog: "العودة إلى المدونة",
      },
      blogDetail: {
        tableOfContents: "جدول المحتويات",
        share: "شارك هذا المقال",
        relatedPosts: "مقالات ذات صلة",
        backToBlog: "العودة إلى المدونة",
      },
      shareLinks: {
        linkedin: "شارك على لينكد إن",
        twitter: "شارك على تويتر",
        facebook: "شارك على فيسبوك",
        instagram: "شارك على إنستغرام",
        whatsapp: "شارك على واتساب",
      },
    },
    contact: {
      seo: {
        title: "اتصل بنا | صِفرون",
        description:
          "تواصل مع صِفرون للحصول على حلول B2B SaaS وخدمات استشارات الأعمال. نحن هنا للمساعدة في تحول أعمالك.",
      },
      hero: {
        title: "دعنا نتواصل",
        subtitle: "نود أن نسمع منك ونساعد في تحول أعمالك",
      },
      form: {
        title: "أرسل لنا رسالة",
        fields: {
          name: "اسمك",
          email: "بريدك الإلكتروني",
          phone: "رقم الهاتف (اختياري)",
          subject: "الموضوع",
          message: "رسالتك",
          purpose: {
            label: "الغرض من الاتصال",
            options: {
              consulting: "استشارات الأعمال",
              products: "المنتجات",
              oasis: "المنتجات - OASIS",
              coaching: "التدريب التنفيذي",
              others: "أخرى",
            },
          },
        },
        button: {
          send: "إرسال الرسالة",
          sending: "جاري الإرسال...",
        },
        messages: {
          success: "تم إرسال الرسالة بنجاح! سنعود إليك قريباً.",
          error: "فشل إرسال الرسالة. يرجى المحاولة مرة أخرى لاحقاً.",
        },
      },
    },
    whatsapp: {
      title: "تحدث معنا على واتساب",
      buttonText: "تحدث معنا",
    },
    // Add more translations as needed
  },
};
