import React from "react";
import { motion } from "framer-motion";
import LeadershipTeamHero from "../components/LeadershipTeamHero";
import LeadershipTeamFounderProfiles from "../components/LeadershipTeamFounderProfiles";
import SEO from "../components/SEO";
import { useTranslation } from "../contexts/TranslationContext";

const LeadershipTeam: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t("leadershipTeam.seo.title")}
        description={t("leadershipTeam.seo.description")}
        keywords={t("leadershipTeam.seo.keywords") as unknown as string[]}
        schema={{
          "@type": "AboutPage",
          mainEntity: {
            "@type": "Organization",
            member: [
              {
                "@type": "Person",
                name: t("leadershipTeam.founders.founderProfiles.0.name"),
                jobTitle: t("leadershipTeam.founders.founderProfiles.0.title"),
                alumniOf: "Osmania University",
                sameAs:
                  "https://www.linkedin.com/in/riazuddin-mohammed-a810108/",
              },
              {
                "@type": "Person",
                name: t("leadershipTeam.founders.founderProfiles.1.name"),
                jobTitle: t("leadershipTeam.founders.founderProfiles.1.title"),
                alumniOf: ["IIT Kharagpur", "IIM Bangalore"],
                sameAs: "https://www.linkedin.com/in/tabishimam/",
              },
            ],
          },
        }}
      />
      <motion.div
        className="min-h-screen bg-gradient-to-b from-gray-50 to-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <LeadershipTeamHero />
          <div id="founder-profiles" className="scroll-mt-24">
            <LeadershipTeamFounderProfiles />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default LeadershipTeam;
