// src/components/CarouselHeader.tsx
import React, { useRef } from "react";
import Slider from "react-slick";
import { useTranslation } from "../contexts/TranslationContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselHeader.css";

const CarouselHeader: React.FC = () => {
  const { t, language } = useTranslation();
  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    cssEase: "linear",
    rtl: language === "ar",
    prevArrow:
      language === "ar" ? (
        <button className="slick-prev">السابق</button>
      ) : undefined,
    nextArrow:
      language === "ar" ? (
        <button className="slick-next">التالي</button>
      ) : undefined,
  };

  const slides = [
    {
      title: t("carousel.vision.title"),
      content: t("carousel.vision.content"),
    },
    {
      title: t("carousel.mission.title"),
      content: t("carousel.mission.content"),
    },
  ];

  return (
    <div className="py-6">
      <div
        className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${
          language === "ar" ? "lg:px-16" : ""
        }`}
      >
        <Slider
          ref={sliderRef}
          {...settings}
          className={`carousel-container ${
            language === "ar" ? "rtl-carousel" : ""
          }`}
        >
          {slides.map((slide, index) => (
            <div key={index} className="carousel-slide">
              <div className="text-center">
                <h2
                  className={`text-3xl font-bold text-background sm:text-4xl mb-4 ${
                    language === "ar" ? "px-8" : ""
                  }`}
                >
                  {slide.title}
                </h2>
                <p
                  className={`text-xl text-background/80 sm:text-2xl ${
                    language === "ar" ? "px-8" : ""
                  }`}
                >
                  {slide.content}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CarouselHeader;
