import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "../contexts/TranslationContext";

interface TestimonialCardProps {
  name: string;
  title: string;
  image: string;
  quote: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  title,
  image,
  quote,
}) => {
  const { language } = useTranslation();

  return (
    <motion.div
      className="p-8 bg-white rounded-xl shadow-lg"
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
        <motion.div
          className="flex-shrink-0"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <img
            src={image}
            alt={name}
            className="w-24 h-24 rounded-full border-4 border-primary/10 object-cover shadow-lg"
          />
        </motion.div>

        <div className="flex-grow space-y-4">
          <div
            className={`text-4xl text-primary/20 ${
              language === "ar" ? "text-right" : "text-left"
            }`}
          >
            "
          </div>
          <p className="text-lg text-gray-700 leading-relaxed text-justify">
            {quote}
          </p>
          <div
            className={`text-4xl text-primary/20 ${
              language === "ar" ? "text-left" : "text-right"
            }`}
          >
            "
          </div>

          <div className="pt-4 border-t border-gray-200">
            <h3 className="text-xl font-bold text-primary">{name}</h3>
            <p className="text-gray-600">{title}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TestimonialCard;
