import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
  FaArrowLeft,
} from "react-icons/fa";
import { useTranslation } from "../contexts/TranslationContext";
import {
  getLocalizedBlogs,
  getLocalizedBlogById,
} from "../data/blogTranslations";
//import { publishedBlogs } from "../data/blogData";
import { formatDateLong } from "../utils/dateFormatter";
//import { boldFirstSentence } from "../utils/boldFirstSentence";
import SEO from "../components/SEO";

const BlogDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t, language } = useTranslation();
  const blog = getLocalizedBlogById(language, id || "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!blog) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            {t("blogs.notFound.title")}
          </h1>
          <Link
            to="/blogs"
            className="inline-flex items-center text-primary hover:text-primary/80 transition-colors"
          >
            <FaArrowLeft
              className={`${language === "ar" ? "ml-2" : "mr-2"}`}
              aria-hidden="true"
            />
            <span>{t("blogs.notFound.backToBlog")}</span>
          </Link>
        </div>
      </div>
    );
  }

  const TableOfContents: React.FC = () => (
    <motion.div
      className="bg-white rounded-xl shadow-lg p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      <h2 className="text-xl font-bold text-primary mb-4">
        {t("blogs.blogDetail.tableOfContents")}
      </h2>
      <ul className="space-y-3">
        {blog.content.map((section) => (
          <motion.li
            key={section.id}
            whileHover={{ x: language === "ar" ? -4 : 4 }}
            transition={{ duration: 0.2 }}
          >
            <button
              onClick={() => {
                document.getElementById(section.id)?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              className="text-gray-600 hover:text-primary transition-colors block text-left w-full"
            >
              {section.title}
            </button>
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );

  const ShareLinks: React.FC = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    const pageTitle = encodeURIComponent(blog.title);

    const shareLinks = [
      {
        name: "LinkedIn",
        icon: <FaLinkedinIn aria-hidden="true" size={20} />,
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${pageTitle}`,
        color: "text-[#0077b5] hover:bg-[#0077b5]/10",
        ariaLabel: t("blogs.shareLinks.linkedin"),
      },
      {
        name: "Twitter",
        icon: <FaTwitter aria-hidden="true" size={20} />,
        url: `https://twitter.com/intent/tweet?url=${currentUrl}&text=${pageTitle}`,
        color: "text-[#1DA1F2] hover:bg-[#1DA1F2]/10",
        ariaLabel: t("blogs.shareLinks.twitter"),
      },
      {
        name: "Facebook",
        icon: <FaFacebookF aria-hidden="true" size={20} />,
        url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
        color: "text-[#4267B2] hover:bg-[#4267B2]/10",
        ariaLabel: t("blogs.shareLinks.facebook"),
      },
      {
        name: "Instagram",
        icon: <FaInstagram aria-hidden="true" size={20} />,
        // Instagram doesn't have a direct URL sharing API, so we'll create a story-friendly link
        url: `https://www.instagram.com/create/story?url=${currentUrl}&text=${pageTitle}`,
        color: "text-[#E4405F] hover:bg-[#E4405F]/10",
        ariaLabel: t("blogs.shareLinks.instagram"),
      },
      {
        name: "WhatsApp",
        icon: <FaWhatsapp aria-hidden="true" size={20} />,
        url: `https://wa.me/?text=${currentUrl}`,
        color: "text-[#25D366] hover:bg-[#25D366]/10",
        ariaLabel: t("blogs.shareLinks.whatsapp"),
      },
    ];

    return (
      <motion.div
        className="bg-white rounded-xl shadow-lg p-6 mt-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <h2 className="text-xl font-bold text-primary mb-4">
          {t("blogs.blogDetail.share")}
        </h2>
        <div className="flex flex-wrap gap-3">
          {shareLinks.map((link) => (
            <motion.a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`p-3 rounded-full ${link.color} transition-all duration-300`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              aria-label={link.ariaLabel}
            >
              {link.icon}
            </motion.a>
          ))}
        </div>
      </motion.div>
    );
  };

  // Get related blogs in the current language
  /*const relatedBlogs = getLocalizedBlogs(language)
    .filter(
      (b) => b.id !== blog.id && b.tags.some((tag) => blog.tags.includes(tag))
    )
    .slice(0, 3);*/

  return (
    <>
      <SEO
        title={`${blog.title} | ${t("blogs.seo.title")}`}
        description={blog.summary}
        keywords={blog.tags}
        type="article"
        publishedTime={blog.date}
        author={blog.author}
        image={blog.image}
      />

      <div
        className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link
              to="/blogs"
              className="inline-flex items-center text-primary hover:text-primary/80 transition-colors mb-8"
              aria-label="Back to blogs list"
            >
              <FaArrowLeft className="mr-2" aria-hidden="true" />
              <span>Back to Blogs</span>
            </Link>
          </motion.div>

          <div className="lg:flex lg:gap-8">
            {/* Main Content */}
            <motion.div
              className="lg:w-3/4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-[400px] object-cover"
                />
                <div className="p-8">
                  <h1 className="text-4xl font-bold text-primary mb-6">
                    {blog.title}
                  </h1>
                  <div className="flex flex-wrap justify-between items-center mb-8 text-gray-600">
                    <span className="font-medium">{blog.author}</span>
                    <span>{formatDateLong(blog.date)}</span>
                  </div>

                  <div className="flex flex-wrap gap-2 mb-8">
                    {blog.tags.map((tag) => (
                      <span
                        key={tag}
                        className="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  {/* Mobile ToC */}
                  <div className="lg:hidden mb-8">
                    <TableOfContents />
                  </div>

                  {/* Content Sections */}
                  {blog.content.map((section) => (
                    <motion.div
                      key={section.id}
                      id={section.id}
                      className="mb-12 scroll-mt-24"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5 }}
                    >
                      <h2 className="text-2xl font-bold text-primary mb-6">
                        {section.title}
                      </h2>

                      <div className="prose prose-lg max-w-none">
                        <ReactMarkdown
                          className="markdown-content text-gray-700 text-justify leading-relaxed"
                          remarkPlugins={[remarkGfm]}
                          components={{
                            img: ({ node, ...props }) => (
                              <div className="my-8">
                                <img
                                  {...props}
                                  className="rounded-lg shadow-lg w-full h-auto"
                                  alt={props.alt || "Blog content image"}
                                />
                              </div>
                            ),
                            p: ({ node, children }) => {
                              return (
                                <p className="mb-6 text-justify text-gray-700 leading-relaxed">
                                  {children}
                                </p>
                              );
                            },
                            h3: ({ node, children }) => (
                              <h3 className="text-xl font-semibold text-primary mt-8 mb-4">
                                {children}
                              </h3>
                            ),
                            ul: ({ node, children }) => (
                              <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-700">
                                {children}
                              </ul>
                            ),
                            ol: ({ node, children }) => (
                              <ol className="list-decimal pl-6 mb-6 space-y-2 text-gray-700">
                                {children}
                              </ol>
                            ),
                            li: ({ node, children }) => (
                              <li className="text-gray-700">{children}</li>
                            ),
                            blockquote: ({ node, children }) => (
                              <blockquote className="border-l-4 border-primary pl-4 italic text-gray-600 my-6">
                                {children}
                              </blockquote>
                            ),
                            code: ({ node, children, ...props }) => {
                              return (
                                <code
                                  className="bg-gray-100 rounded px-2 py-1 text-sm font-mono"
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            },
                            a: ({ node, href = "", children }) => {
                              const baseClasses =
                                "text-primary hover:text-primary/80 transition-colors duration-200 font-medium";

                              // Handle internal links (starting with '/')
                              if (href.startsWith("/")) {
                                return (
                                  <Link
                                    to={href}
                                    className={`${baseClasses} inline-flex items-center gap-1`}
                                  >
                                    {children}
                                    <svg
                                      className="w-4 h-4"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                                      />
                                    </svg>
                                  </Link>
                                );
                              }

                              // Handle external links
                              return (
                                <a
                                  href={href}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`${baseClasses} inline-flex items-center gap-1`}
                                >
                                  {children}
                                  <svg
                                    className="w-3.5 h-3.5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                    />
                                  </svg>
                                </a>
                              );
                            },
                            strong: ({ node, children }) => (
                              <strong className="font-bold text-gray-900">
                                {children}
                              </strong>
                            ),
                            em: ({ node, children }) => (
                              <em className="italic text-gray-800">
                                {children}
                              </em>
                            ),
                            hr: () => (
                              <hr className="my-8 border-t border-gray-200" />
                            ),
                            h1: ({ node, children }) => (
                              <h1 className="text-3xl font-bold text-primary my-6">
                                {children}
                              </h1>
                            ),
                            h2: ({ node, children }) => (
                              <h2 className="text-2xl font-bold text-primary my-5">
                                {children}
                              </h2>
                            ),
                            h4: ({ node, children }) => (
                              <h4 className="text-lg font-semibold text-primary my-4">
                                {children}
                              </h4>
                            ),
                            table: ({ node, children }) => (
                              <div className="overflow-x-auto my-8">
                                <table className="min-w-full divide-y divide-gray-200">
                                  {children}
                                </table>
                              </div>
                            ),
                            thead: ({ node, children }) => (
                              <thead className="bg-gray-50">{children}</thead>
                            ),
                            tbody: ({ node, children }) => (
                              <tbody className="bg-white divide-y divide-gray-200">
                                {children}
                              </tbody>
                            ),
                            tr: ({ node, children }) => <tr>{children}</tr>,
                            td: ({ node, children }) => (
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {children}
                              </td>
                            ),
                            th: ({ node, children }) => (
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {children}
                              </th>
                            ),
                          }}
                        >
                          {section.content}
                        </ReactMarkdown>
                        {section.image && (
                          <motion.img
                            src={section.image}
                            alt={section.title}
                            className="w-full rounded-lg shadow-lg mt-6"
                            initial={{ opacity: 0, scale: 0.95 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                          />
                        )}
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              {/* Mobile Share Links */}
              <div className="lg:hidden">
                <ShareLinks />
              </div>
            </motion.div>

            {/* Sidebar */}
            <div className="lg:w-1/4">
              <motion.div
                className="hidden lg:block sticky top-8 space-y-6"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <TableOfContents />
                <ShareLinks />

                {/* Related Posts */}
                <motion.div
                  className="bg-white rounded-xl shadow-lg p-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <h2 className="text-xl font-bold text-primary mb-4">
                    {t("blogs.blogDetail.relatedPosts")}
                  </h2>
                  <div className="space-y-4">
                    {getLocalizedBlogs(language)
                      .filter(
                        (b) =>
                          b.id !== blog.id &&
                          b.tags.some((tag) => blog.tags.includes(tag))
                      )
                      .slice(0, 3)
                      .map((relatedBlog) => (
                        <Link
                          key={relatedBlog.id}
                          to={`/blog/${relatedBlog.id}`}
                          className="block group"
                          aria-label={`Read ${relatedBlog.title}`}
                        >
                          <div
                            className={`flex items-start ${
                              language === "ar"
                                ? "space-x-reverse"
                                : "space-x-3"
                            }`}
                          >
                            <img
                              src={relatedBlog.image}
                              alt="" // Decorative image, title is in heading
                              className="w-16 h-16 rounded-lg object-cover"
                            />
                            <div>
                              <h3 className="text-sm font-medium group-hover:text-primary transition-colors line-clamp-2">
                                {relatedBlog.title}
                              </h3>
                              <p className="text-xs text-gray-500 mt-1">
                                {formatDateLong(relatedBlog.date)}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;
