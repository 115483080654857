import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import { useTranslation } from "../contexts/TranslationContext";
import { getLocalizedBlogs } from "../data/blogTranslations";
import { formatDate } from "../utils/dateFormatter";

const Blogs: React.FC = () => {
  const { t, language } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  // Get blogs in current language
  const blogs = getLocalizedBlogs(language);

  const sortedBlogs = [...blogs].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = sortedBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
  const totalPages = Math.ceil(sortedBlogs.length / blogsPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <>
      <SEO
        title={t("blogs.seo.title")}
        description={t("blogs.seo.description")}
        keywords={[
          "Business Blog",
          "Technology Insights",
          "Digital Transformation",
          "Industry Trends",
        ]}
        type="blog"
      />

      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-primary mb-6">
              {t("blogs.hero.title")}
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {t("blogs.hero.subtitle")}
            </p>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            variants={container}
            initial="hidden"
            animate="show"
          >
            {currentBlogs.map((blog) => (
              <motion.div key={blog.id} variants={item}>
                <Link to={`/blog/${blog.id}`} className="block h-full">
                  <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
                    <div className="relative h-48">
                      <img
                        src={blog.image}
                        alt={blog.title}
                        className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
                    </div>

                    <div className="p-6 space-y-4">
                      <h2 className="text-2xl font-bold text-primary line-clamp-2 hover:text-primary/80 transition-colors">
                        {blog.title}
                      </h2>

                      <p className="text-gray-600 line-clamp-3">
                        {blog.summary}
                      </p>

                      <div className="flex flex-wrap gap-2">
                        {blog.tags.map((tag) => (
                          <span
                            key={tag}
                            className="inline-block bg-primary/10 text-primary rounded-full px-3 py-1 text-sm font-medium"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>

                      <div className="pt-4 flex justify-between items-center border-t border-gray-100">
                        <span className="text-sm font-medium text-gray-600">
                          {blog.author}
                        </span>
                        <span className="text-sm text-gray-500">
                          {formatDate(blog.date)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </motion.div>

          {totalPages > 1 && (
            <motion.div
              className="mt-16 flex justify-center gap-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 rounded-lg bg-white shadow-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors"
              >
                {t("blogs.pagination.previous")}
              </button>

              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`w-10 h-10 rounded-lg ${
                    currentPage === i + 1
                      ? "bg-primary text-white"
                      : "bg-white hover:bg-gray-50"
                  } shadow-md transition-colors`}
                >
                  {i + 1}
                </button>
              ))}

              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 rounded-lg bg-white shadow-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors"
              >
                {t("blogs.pagination.next")}
              </button>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default Blogs;
