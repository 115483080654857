import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SEO from "../components/SEO";
import { useTranslation } from "../contexts/TranslationContext";

const About = () => {
  const { t, language } = useTranslation();

  const approaches = [
    {
      name: t("about.approaches.items.0.name"),
      image: "/images/b2b_saas.jfif",
      desc: t("about.approaches.items.0.desc"),
      action: t("about.approaches.items.0.action"),
      url: "/products",
      icon: "💻",
    },
    {
      name: t("about.approaches.items.1.name"),
      image: "/images/business_consulting.jfif",
      desc: t("about.approaches.items.1.desc"),
      action: t("about.approaches.items.1.action"),
      url: "/consulting",
      icon: "📈",
    },
    {
      name: t("about.approaches.items.2.name"),
      image: "/images/exec_coaching.jfif",
      desc: t("about.approaches.items.2.desc"),
      action: t("about.approaches.items.2.action"),
      url: "/contact",
      icon: "🎯",
    },
  ];
  // Animation variants
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: { duration: 0.6 },
  };

  const staggerChildren = {
    initial: { opacity: 0 },
    whileInView: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
    viewport: { once: true },
  };

  // Carousel settings
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const images = [
    "/images/hero-image1.webp", // Replace with actual image paths
    "/images/hero-image2.webp",
    "/images/hero-image3.webp",
  ];

  const HeroCarousel = () => {
    return (
      <Slider {...carouselSettings} className="w-full h-80 lg:h-[500px]">
        {images.map((src, index) => (
          <div key={index} className="w-full h-full">
            <img
              src={src}
              alt={`Hero Slide ${index + 1}`}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ))}
      </Slider>
    );
  };

  // Define hero features for animation
  /*const heroFeatures = [
    { icon: "🚀", text: "From Zero to One Philosophy" },
    { icon: "💡", text: "First Principles Thinking" },
    { icon: "🎯", text: "Business Transformation" },
    { icon: "🔄", text: "Continuous Innovation" },
  ];*/

  return (
    <>
      <SEO
        title={t("about.seo.title")}
        description={t("about.seo.description")}
        schema={{
          "@type": "Organization",
          name: "CyfrOne",
          foundingDate: "2023",
          founders: [
            {
              "@type": "Person",
              name: "Riaz Mohammed",
            },
            {
              "@type": "Person",
              name: "Tabish Imam",
            },
          ],
          description: t("about.seo.description"),
        }}
      />

      <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen">
        {/*Hero Section*/}
        <motion.section
          className="relative py-20 overflow-hidden bg-gradient-to-r from-primary/5 to-primary/10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:gap-12">
              <motion.div
                className={`text-center lg:text-left lg:w-1/2 ${
                  language === "ar" ? "lg:order-2" : ""
                }`}
                variants={{
                  initial: { opacity: 0, x: language === "ar" ? 20 : -20 },
                  animate: { opacity: 1, x: 0 },
                }}
                initial="initial"
                animate="animate"
                transition={{ duration: 0.8 }}
              >
                <motion.h1
                  className="text-4xl md:text-5xl lg:text-6xl font-bold text-primary mb-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  {t("about.hero.title")}
                  <span className="block text-3xl md:text-4xl lg:text-5xl mt-2 text-gray-600">
                    {t("about.hero.withText")}{" "}
                    <img
                      src="/images/blue_logo.png"
                      alt="CyfrOne Logo"
                      className="inline-block h-12"
                    />
                  </span>
                </motion.h1>

                <motion.p
                  className="text-xl text-gray-600 mb-8 max-w-xl mx-auto lg:mx-0"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  {t("about.hero.description")}
                </motion.p>

                <motion.div
                  className="flex flex-wrap gap-4 justify-center lg:justify-start"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 }}
                >
                  <Link
                    to="/contact"
                    className="px-8 py-3 bg-primary text-white rounded-full text-lg font-semibold hover:bg-primary/90 transition-colors"
                  >
                    {t("about.hero.getStarted")}
                  </Link>
                  <Link
                    to="/products"
                    className="px-8 py-3 border-2 border-primary text-primary rounded-full text-lg font-semibold hover:bg-primary/10 transition-colors"
                  >
                    {t("about.hero.ourSolutions")}
                  </Link>
                </motion.div>
              </motion.div>

              <motion.div
                className="hidden lg:block lg:w-1/2"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
              >
                <HeroCarousel />
              </motion.div>
            </div>
          </div>
        </motion.section>

        {/* About Section */}
        <motion.section
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
          {...fadeInUp}
        >
          <div className="bg-white shadow-xl rounded-2xl p-6 sm:p-8 backdrop-blur-sm bg-opacity-90">
            <h1 className="text-3xl sm:text-4xl font-bold mb-6 text-primary text-center">
              {t("about.aboutSection.title")}
            </h1>
            <div className="space-y-4 text-gray-700">
              <p className="text-lg leading-relaxed text-justify">
                {t("about.aboutSection.paragraph1")}
              </p>
              <p className="text-lg leading-relaxed text-justify">
                {t("about.aboutSection.paragraph2")}
              </p>
              <p className="text-lg leading-relaxed text-justify">
                {t("about.aboutSection.paragraph3")}
              </p>
            </div>
          </div>
        </motion.section>

        {/* Founders Story */}
        <motion.section
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
          {...fadeInUp}
        >
          <div className="bg-white shadow-xl rounded-2xl p-6 sm:p-8 overflow-hidden relative">
            <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-primary text-center">
              {t("about.foundersStory.title")}
            </h2>
            <div className="relative">
              <motion.img
                src="/images/about_us.webp"
                alt={t("about.foundersStory.imageAlt")}
                className="w-full max-w-2xl mx-auto rounded-lg shadow-lg mb-6"
                initial={{ scale: 1.1, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              />
              <div className="space-y-4 text-gray-700">
                <p className="text-lg leading-relaxed text-justify">
                  {t("about.foundersStory.paragraph1")}
                </p>
                <p className="text-lg leading-relaxed text-justify">
                  {t("about.foundersStory.paragraph2")}
                </p>
              </div>
              <div className="mt-8 text-center">
                <Link
                  to="/leadership-team#founder-profiles"
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary/90 transition-colors duration-300"
                >
                  {t("about.foundersStory.viewProfiles")}
                </Link>
              </div>
            </div>
          </div>
        </motion.section>

        {/* Approaches Section */}
        <motion.section
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
          variants={staggerChildren}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          <div className="bg-white shadow-xl rounded-2xl p-6 sm:p-8">
            <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-primary text-center">
              {t("about.approaches.title")}
            </h2>
            <p className="text-lg text-gray-700 mb-12 text-justify">
              {t("about.approaches.intro")}
            </p>

            <div className="space-y-16">
              {approaches.map((approach, index) => (
                <motion.div
                  key={approach.name}
                  className="relative"
                  variants={fadeInUp}
                >
                  <div
                    className={`flex flex-col ${
                      language === "ar"
                        ? index % 2 === 0
                          ? "md:flex-row-reverse"
                          : "md:flex-row"
                        : index % 2 === 0
                        ? "md:flex-row"
                        : "md:flex-row-reverse"
                    } gap-8 items-center`}
                  >
                    <div className="w-full md:w-1/2">
                      <div className="relative group">
                        <motion.img
                          src={approach.image}
                          alt={approach.name}
                          className="w-full h-80 object-cover rounded-lg shadow-lg transform group-hover:scale-105 transition-transform duration-300"
                          initial={{ opacity: 0, scale: 0.9 }}
                          whileInView={{ opacity: 1, scale: 1 }}
                          viewport={{ once: true }}
                        />
                        <div className="absolute inset-0 bg-primary/10 group-hover:bg-primary/20 transition-colors duration-300 rounded-lg" />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className="space-y-4">
                        <h3 className="text-2xl font-bold text-primary flex items-center gap-2">
                          <span>{approach.icon}</span>
                          {approach.name}
                        </h3>
                        <p className="text-gray-700 text-justify leading-relaxed">
                          {approach.desc}
                        </p>
                        <div className="pt-4">
                          <Link
                            to={approach.url}
                            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary/90 transition-colors duration-300"
                          >
                            {approach.action}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index < approaches.length - 1 && (
                    <div className="my-16 border-t border-gray-200" />
                  )}
                </motion.div>
              ))}
            </div>

            <motion.p
              className="text-lg text-gray-700 mt-12 text-justify"
              variants={fadeInUp}
            >
              {t("about.approaches.conclusion")}
            </motion.p>
          </div>
        </motion.section>

        {/* New CTA Section */}
        <motion.section
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
          variants={fadeInUp}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          <div className="bg-primary rounded-2xl p-8 md:p-12 text-white text-center relative overflow-hidden">
            <div
              style={{ backgroundImage: "url('/images/pattern.svg')" }}
              className="absolute inset-0 opacity-10"
            />

            <motion.div
              className="relative z-10 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                {t("about.cta.title")}
              </h2>
              <p className="text-xl text-white/90 mb-8">
                {t("about.cta.description")}
              </p>
              <div className="flex flex-wrap gap-4 justify-center">
                <Link
                  to="/contact"
                  className="px-8 py-3 bg-white text-primary rounded-full text-lg font-semibold hover:bg-gray-100 transition-colors"
                >
                  {t("about.cta.consultation")}
                </Link>
                <Link
                  to="/products"
                  className="px-8 py-3 border-2 border-white text-white rounded-full text-lg font-semibold hover:bg-white/10 transition-colors"
                >
                  {t("about.cta.explore")}
                </Link>
              </div>
            </motion.div>
          </div>
        </motion.section>
      </div>
    </>
  );
};

export default About;
